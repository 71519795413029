
$Head_Color:#0e2a47;

.p-component
{
    font-family:Poppins;
}

.p-datatable .p-sortable-column.p-highlight {
    background: #f4f4f4!important;
    color: Black!important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: $Head_Color!important;
    color: #ffffff!important;
}

.p-datatable .p-datatable-thead > tr > th {
   
    font-weight: 600!important;
    font-size: 15px!important;
}


.green_
{
    background-color: rgb(177, 242, 177);
}

.red_
{
    background-color: rgb(255, 163, 163);
}

.Qlayout {
    width: 100%;

    .Qhead {

        width: 100%;
        padding: 10px;
        background-color: white;
        height: 40px;
        border-bottom: 1px solid #e8ebf1;

        .left_text {
            font-size: 18px;
            font-weight: 600;
            float: left;
        }

        .right_text {
            font-size: 14px;
            float: right;
        }

    }


    .Qbody {
        width: 100%;
        background-color: white;
        display: inline-flex;
    }
}

.Qlayout_1 {
    width: 100%;

    .Qhead {

        width: 100%;
        padding: 10px;
        background-color: white;
        height: 50px;
        border-bottom: 1px solid #e8ebf1;

        .left_text {
            font-size: 18px;
            font-weight: 600;
            float: left;
        }

        .right_text {
            font-size: 14px;
            float: right;

        }

    }


    .Qbody {
        width: 100%;
        background-color: white;
        display: inline-flex;
        height: calc(100vh - 165px);
        overflow: auto;
    }

    .Qfooter {
        width: 100%;
        bottom: 0;
        font-weight: 600;
        color: #333333;
        background-color: #f4f4f4;
        padding: 0.571em 0.857em;
        position: sticky;
        border: 1px solid #c8c8c8;
    }

    .G_Search {
        display: block;
        width: 100%;
        height: 30px;
        padding: 10px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fafafa;
        background-clip: padding-box;
        border: 0px solid #ced4da;
        border-radius: 2.25rem;
        
    }

}

.Filter_Bar {
    background-color: #0e4e95;
    color: white;
    width: 100%;
    margin-top: -8px;
    line-height: 36px;
}

.mobile_Save_panel
{
    margin-top: 10px;
    text-align: center;
    padding-bottom: 10px;
}


@media(width<=720px) {



    .mobile_Save_panel
    {
        background-color: rgb(243, 243, 243);
        padding-top: 5px;
        width: 100%;
        position: fixed;
        height: 50px;
        z-index: 999999;
        display: inline-flex;
        text-align: center;
        right: 0px;


        .bt_save
        {width: 100%;
            max-width: 250px;
            position: fixed;
            /* left: 10%; */
            right: 25px;
            background-color: #03034a;
            border-color: #03034a;
        }

        .bt_cancel
        {
            display: none;
        }
        
        bottom: 0px;
    }

    .Qlayout {
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 999;
        height: 100vh;
        overflow: auto;
        background-color: white;
        padding-bottom: 60px;

        .Qhead {

            width: 100%;
            padding: 10px;
            background-color: white;
            height: 50px;
            border-bottom: 1px solid #e8ebf1;
            color: white;
            background-color: $Head_Color;

            .left_text {
                font-size: 18px;
                font-weight: 600;
                float: left;
                display: inline-flex;

                .arrow {
                    font-size: 25px;
                    padding-left: 3px;
                    padding-right: 10px;
                    cursor: pointer;
                }
            }

            .right_text {
                font-size: 14px;
                float: right;
            }


        }

        .Qbody {
            width: 100%;
            background-color: white;
            display: block;
        }


    }


    .m_white {
        color: white !important;
    }


    .Qlayout_1 {
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 999;
        height: 100vh;
        overflow: auto;
        background-color: white;

        .Qhead {

            width: 100%;
            padding: 10px;
            background-color: white;
            height: 50px;
            border-bottom: 1px solid #e8ebf1;
            color: white;
            background-color: $Head_Color;

            .left_text {
                font-size: 18px;
                font-weight: 600;
                float: left;
                display: inline-flex;

                .arrow {
                    font-size: 25px;
                    padding-left: 3px;
                    padding-right: 10px;
                    cursor: pointer;
                }
            }

            .right_text {
                font-size: 14px;
                float: right;
            }


        }

        .Qbody {
            width: 100%;
            background-color: white;
            display: block;
            height: calc(100vh - 90px);
            overflow: auto;
        }


        .Qfooter {
            width: 100%;
            bottom: 0;
            font-weight: 600;
            color: #333333;
            background-color: #f4f4f4;
            padding: 0.571em 0.857em;
            position: fixed;
            bottom: 0;


            border: 1px solid #c8c8c8;
        }

        .G_Search {
            display: block;
            width: 100%;
            height: 30px;
            padding: 10px;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fafafa;
            background-clip: padding-box;
            border: 0px solid #ced4da;
            border-radius: 2.25rem;
            max-width: 150px;
        }

        .Search_Panel {
            top: 14px !important;
            right: -10px !important;
        }

    }

}


.ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon {
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    position: absolute;
    font-size: 11px!important;
    font-weight: bold!important;
    color: #495057!important;
    margin-left: -1px;
}


.qtitle
{
    font-size: 16px;
    font-weight: 800;
    margin-top: 10px;
    margin-bottom: -5px;
}




.qavator_body
{
    position: relative;
    display: inline-block;
    margin-bottom: 30px;
    margin-top: 10px;
}

.qimage
{
    padding: .25rem;
    background-color:#f3f3f9;
    border:1px solid #e9ebec;
    border-radius: 50% !important;
    width: 7.5rem;
    height: 7.5rem;
    object-fit: cover;
}



.qavator
{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    font-weight: 800;
    font-size: 21px;
    padding: 3px;
    -webkit-box-align: center;
    align-items: center;
    padding-top: -6px;
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    cursor: pointer;
    background-color: #d9dde2;

    span
    {
        padding-left: 3px;
    }
}


.qimage1
{
    padding: .25rem;
    background-color:#f3f3f9;
    border:1px solid #e9ebec;
    
    width: 7.5rem;
    height: 7.5rem;
    object-fit: cover;
}


.qavator_body1
{
    position: relative;
    display: inline-block;
}
.qavator1
{
    height: 2rem;
    width: 2rem;
    
    font-weight: 800;
    font-size: 21px;
    padding: 3px;
    -webkit-box-align: center;
    align-items: center;
    padding-top: -6px;
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    cursor: pointer;
    background-color: #d9dde2;

    span
    {
        padding-left: 3px;
    }
}
